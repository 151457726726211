import React, { Fragment } from 'react';
import { DataTitleCopyable } from './data-title';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
const SliderDataEntry = (props) => {
    const marks = {
        ["None"]: React.createElement("strong", null, "n/a"),
        [props.min]: React.createElement("strong", null, props.min),
        [props.max]: React.createElement("strong", null, props.max)
    };
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, copy: props.copy }),
        React.createElement("div", { className: "slider-container" },
            React.createElement(Slider, { value: props.value || 0, defaultValue: 0, max: props.max, min: 0, dots: props.dots, marks: marks, disabled: props.mode === 'view' || props.disabled, onChange: e => props.onChange(props.slug, typeof (e) == "number" ? (e ? e : null) : e[0]) })),
        React.createElement("p", { className: "slider-score" },
            "Your score: ",
            props.value ? props.value : "Not yet entered",
            " ",
            React.createElement("span", { className: "slider-average" },
                "Average: ",
                props.average,
                " (",
                props.votes,
                " voters)"))));
};
export default SliderDataEntry;
