import React from 'react';
import './logo.css';
let config = require('../../cc-config.json');
/**
 * Logo
 *
 * As link to homepage, used in top header
 */
const Logo = (props) => {
    const variantClass = props.variant === 'default' ? '' : props.variant;
    return (React.createElement("div", { className: `logo ${variantClass}` },
        React.createElement(LogoGrid, null),
        React.createElement("h1", { className: "logotype" },
            React.createElement("span", null, "Colouring"),
            React.createElement("span", null, config.cityName))));
};
const LogoGrid = () => (React.createElement("div", { className: "grid" },
    React.createElement("div", { className: "row" },
        React.createElement("div", { className: "cell background-location" }),
        React.createElement("div", { className: "cell background-land-use" }),
        React.createElement("div", { className: "cell background-typology-size" }),
        React.createElement("div", { className: "cell background-construction-design" })),
    React.createElement("div", { className: "row" },
        React.createElement("div", { className: "cell background-age-history" }),
        React.createElement("div", { className: "cell background-planning-conservation" }),
        React.createElement("div", { className: "cell background-retrofit-condition" }),
        React.createElement("div", { className: "cell background-energy-performance" })),
    React.createElement("div", { className: "row" },
        React.createElement("div", { className: "cell background-water-green-infrastructure" }),
        React.createElement("div", { className: "cell background-urban-infrastructure" }),
        React.createElement("div", { className: "cell background-disaster-management" }),
        React.createElement("div", { className: "cell background-community" }))));
export { Logo };
