import React, { Fragment } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DataPickerDataEntry = (props) => {
    let date = props.value == null ? null : new Date(props.value);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "row date-picker" },
            React.createElement("div", { className: 'column date-picker-label' },
                React.createElement("label", null, props.title)),
            React.createElement("div", { className: 'column date-picker-dropdown' },
                React.createElement(DatePicker, { showIcon: true, dateFormat: "dd/MM/yyyy", slug: props.slug, selected: date, onChange: e => {
                        props.onChange(props.slug, e === null ?
                            null :
                            e);
                    }, title: props.tooltip, isClearable: !(props.mode === 'view' || props.disabled), placeholderText: "Select a date", maxDate: new Date(), disabled: props.mode === 'view' || props.disabled })))));
};
export default DataPickerDataEntry;
