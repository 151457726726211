import React, { Fragment } from 'react';
import '../../map/map-button.css';
import { commonSourceTypes, dataFields } from '../../config/data-fields-config';
import NumericDataEntry from '../data-components/numeric-data-entry';
import DataEntry from '../data-components/data-entry';
import { DataEntryGroup } from '../data-components/data-entry-group';
import SelectDataEntry from '../data-components/select-data-entry';
import Verification from '../data-components/verification';
import withCopyEdit from '../data-container';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
/**
* Urban Infrastructure Context view/edit section
*/
const UrbanInfrastructureView = (props) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const subcat = queryParameters.get("sc");
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Street/Pavement", collapsed: subcat == null || subcat != "2" },
            React.createElement(DataEntry, { title: "Walkability Index", slug: "context_walkability_index", value: "", mode: 'view', tooltip: 'Under development' }),
            React.createElement("hr", null),
            React.createElement(NumericDataEntry, { title: dataFields.context_street_width.title, value: props.building.context_street_width, slug: "context_street_width", tooltip: dataFields.context_street_width.tooltip, 
                //placeholder={dataFields.context_street_width.example}
                copy: props.copy, mode: props.mode, onChange: props.onChange, step: 1, min: 0 }),
            React.createElement(Verification, { slug: "context_street_width", allow_verify: props.user !== undefined && props.building.context_street_width !== null, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("context_street_width"), user_verified_as: props.user_verified.context_street_width, verified_count: props.building.verified.context_street_width }),
            React.createElement(SelectDataEntry, { title: dataFields.context_street_width_source_type.title, slug: "context_street_width_source_type", value: props.building.context_street_width_source_type, options: dataFields.context_street_width_source_type.items, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_street_width_source_type.tooltip }),
            (props.building.context_street_width_source_type == commonSourceTypes[0] ||
                props.building.context_street_width_source_type == commonSourceTypes[1] ||
                props.building.context_street_width_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.context_street_width_source_links.title, slug: "context_street_width_source_links", value: props.building.context_street_width_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_street_width_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            React.createElement(NumericDataEntry, { title: dataFields.context_pavement_width.title, value: props.building.context_pavement_width, slug: "context_pavement_width", tooltip: dataFields.context_pavement_width.tooltip, 
                //placeholder={dataFields.context_pavement_width.example}
                mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 0 }),
            React.createElement(Verification, { slug: "context_pavement_width", allow_verify: props.user !== undefined && props.building.context_pavement_width !== null, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("context_pavement_width"), user_verified_as: props.user_verified.context_pavement_width, verified_count: props.building.verified.context_pavement_width }),
            React.createElement(SelectDataEntry, { title: dataFields.context_pavement_width_source_type.title, slug: "context_pavement_width_source_type", value: props.building.context_pavement_width_source_type, options: dataFields.context_pavement_width_source_type.items, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_pavement_width_source_type.tooltip }),
            (props.building.context_pavement_width_source_type == commonSourceTypes[0] ||
                props.building.context_pavement_width_source_type == commonSourceTypes[1] ||
                props.building.context_pavement_width_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.context_pavement_width_source_links.title, slug: "context_pavement_width_source_links", value: props.building.context_pavement_width_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_pavement_width_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            React.createElement(DataEntry, { title: dataFields.context_street_geometry.title, slug: "context_street_geometry", value: props.building.context_street_geometry, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_street_geometry.tooltip, placeholder: "https://...", isUrl: true }),
            React.createElement(Verification, { slug: "context_street_geometry", allow_verify: props.user !== undefined && props.building.context_street_geometry !== null, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("context_street_geometry"), user_verified_as: props.user_verified.context_street_geometry, verified_count: props.building.verified.context_street_geometry }),
            React.createElement(SelectDataEntry, { title: dataFields.context_street_geometry_source_type.title, slug: "context_street_geometry_source_type", value: props.building.context_street_geometry_source_type, options: dataFields.context_street_geometry_source_type.items, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_street_geometry_source_type.tooltip }),
            (props.building.context_street_geometry_source_type == commonSourceTypes[0] ||
                props.building.context_street_geometry_source_type == commonSourceTypes[1] ||
                props.building.context_street_geometry_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.context_street_geometry_source_links.title, slug: "context_street_geometry_source_links", value: props.building.context_street_geometry_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.context_street_geometry_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Number of Entrances Facing Street", collapsed: subcat == null || subcat != "3" },
            React.createElement(DataEntry, { title: "Number of entrances facing street", slug: "", value: "", mode: 'view', tooltip: 'Under development' }))));
};
const UrbanInfrastructureContainer = withCopyEdit(UrbanInfrastructureView);
export default UrbanInfrastructureContainer;
