import React, { Fragment } from 'react';
import { commonSourceTypes, dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import withCopyEdit from '../data-container';
import Verification from '../data-components/verification';
import { DataEntryGroup } from '../data-components/data-entry-group';
/**
 * Use view/edit section
 */
const LandUseView = (props) => {
    const switchToIsDomesticMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('is_domestic');
    };
    const switchToLandUseMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('landuse');
    };
    const queryParameters = new URLSearchParams(window.location.search);
    const subcat = queryParameters.get("sc");
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Specific Land Use/s", collapsed: subcat == null || subcat != "1" },
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement("i", null, "The vast majority of properties are residential (93% in the UK), so we have set 'residential' as the default value. Can you help us identify non-residential and mixed use buildings (and verify residential buildings too)?")),
            (props.mapColourScale != "landuse") ?
                React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToLandUseMapStyle }, "Click to see specific land use.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(MultiDataEntry, { title: dataFields.current_landuse_group.title, slug: "current_landuse_group", value: props.building.current_landuse_group, mode: props.mode, copy: props.copy, onChange: props.onChange, confirmOnEnter: true, tooltip: dataFields.current_landuse_group.tooltip, placeholder: "Enter new land use group here", copyable: true, autofill: true, showAllOptionsOnEmpty: true }),
            React.createElement(Verification, { slug: "current_landuse_group", allow_verify: props.user !== undefined && props.building.current_landuse_group !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("current_landuse_group"), user_verified_as: props.user_verified.current_landuse_group && props.user_verified.current_landuse_group.join(", "), verified_count: props.building.verified.current_landuse_group }),
            React.createElement(SelectDataEntry, { title: dataFields.current_landuse_source.title, slug: "current_landuse_source", value: props.building.current_landuse_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.current_landuse_source.tooltip, placeholder: dataFields.current_landuse_source.example, options: dataFields.current_landuse_source.items }),
            (props.building.current_landuse_source == commonSourceTypes[0] ||
                props.building.current_landuse_source == commonSourceTypes[1] ||
                props.building.current_landuse_source == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.current_landuse_link.title, slug: "current_landuse_link", value: props.building.current_landuse_link, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.current_landuse_link.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement(DataEntry, { title: dataFields.current_landuse_order.title, tooltip: dataFields.current_landuse_order.tooltip, slug: "current_landuse_order", value: props.building.current_landuse_order, mode: props.mode, disabled: true, copy: props.copy, onChange: props.onChange })),
        React.createElement(DataEntryGroup, { name: "General Land Use", collapsed: subcat == null || subcat != "2" },
            (props.mapColourScale != "is_domestic") ?
                React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToIsDomesticMapStyle }, "Click to see residential, non-residential and mixed-use buildings.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(SelectDataEntry, { title: dataFields.is_domestic.title, slug: "is_domestic", value: props.building.is_domestic, options: dataFields.is_domestic.items, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.is_domestic.tooltip }))));
};
const LandUseContainer = withCopyEdit(LandUseView);
export default LandUseContainer;
