import React from 'react';
import { DataTitleCopyable } from './data-title';
export const MultiSelectDataEntry = (props) => {
    var _a;
    const slugWithModifier = props.slug + ((_a = props.slugModifier) !== null && _a !== void 0 ? _a : '');
    function handleChange(e) {
        const changedKey = e.target.name;
        const checked = e.target.checked;
        const newVal = Object.assign(Object.assign({}, props.value), { [changedKey]: checked || null });
        props.onChange(slugWithModifier, newVal);
    }
    return (React.createElement(React.Fragment, null,
        props.showTitle !== false &&
            React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        props.options.map(o => (React.createElement(React.Fragment, null,
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", disabled: props.mode === 'view' || props.disabled, name: o.key, checked: props.value && props.value[o.key], onChange: handleChange }),
                o.label),
            React.createElement("br", null))))));
};
