import React, { Fragment } from 'react';
import './supporter-logos.css';
const SupporterLogos = () => (React.createElement(Fragment, null,
    React.createElement("h2", { className: "h3" }, "Lead Organisation and Project Partners"),
    React.createElement("ul", { className: "logo-list" },
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.ucl.ac.uk/bartlett/casa/" },
                React.createElement("img", { src: "images/logo-casa.png", alt: "Centre for Advanced Spatial Analysis (CASA)" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.ucl.ac.uk/" },
                React.createElement("img", { src: "images/logo-ucl.png", alt: "University College London" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.historicengland.org.uk/" },
                React.createElement("img", { src: "images/logo-he.png", alt: "Historic England" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.ordnancesurvey.co.uk/" },
                React.createElement("img", { src: "images/logo-os.png", alt: "Ordnance Survey" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.london.gov.uk/" },
                React.createElement("img", { src: "images/logo-gla.png", alt: "Supported by the Mayor of London" }))))));
export default SupporterLogos;
